<template>
  <div class="home">
    <div class="news-header">
      <h2 class="vertical-align-middle">新闻中心</h2>
    </div>
    <div class="news-minimenu">
      <div class="container">
        <b-icon icon="shift-fill" width="18" height="18"></b-icon>
        <router-link to="/">网站首页</router-link>/
        <router-link to="/news">新闻中心</router-link>/
        <router-link
          :to="{ path: '/news?news_type=' + chooseNewsType.dictValue }"
          v-if="chooseNewsType"
          >{{ chooseNewsType.dictLabel }}</router-link
        >
      </div>
    </div>
    <div class="news-cont container news-details">
      <div class="news-CL col-md-9">
        <div v-if="news != null">
          <section class="details-title border-bottom1">
            <h1 class="m-0">{{ news.newsTitle }}</h1>
            <div class="info font-weight-300">
              <span>{{ news.createTime }}</span>
              <b-icon icon="eye-fill" width="18" height="18"></b-icon>
              <span>{{ news.hits }}</span>
            </div>
          </section>
          <section class="met-editor clearfix">
            <div style="white-space: pre-wrap" v-html="news.newsContent"></div>
          </section>
          <ul class="pagination">
            <li class="page-item" v-if="prevNews">
              <router-link
                class="page-link"
                :to="{ path: 'newsdetails', query: { id: prevNews.newsId } }"
                >上一页：{{
                  prevNews.newsTitle | filtersNewsTitle
                }}</router-link
              >
            </li>
            <li class="page-item disabled" v-else>
              <a class="page-link">上一页：没有了</a>
            </li>
            <li class="page-item" v-if="nextNews">
              <router-link
                class="page-link"
                :to="{ path: 'newsdetails', query: { id: nextNews.newsId } }"
                >下一页：{{
                  nextNews.newsTitle | filtersNewsTitle
                }}</router-link
              >
            </li>
            <li class="page-item disabled" v-else>
              <a class="page-link" href="#">下一页：没有了</a>
            </li>
          </ul>
        </div>
        <div class="no-news" v-else>
          <div v-show="show">
            新闻不存在，{{ count }}秒后将跳转<router-link to="/news"
              >新闻中心</router-link
            >
          </div>
        </div>
      </div>
      <!-- 新闻右 -->
      <div class="news-CR col-md-3">
        <!--<div class="form-group">
          <div class="input-search">
            <button type="submit" class="input-search-btn butbg">
              <b-icon icon="search" width="18" height="18"></b-icon>
            </button>
            <input
              type="text"
              class="form-control"
              name="searchword"
              placeholder="search"
            />
          </div>
        </div>-->
        <div class="left-menu">
          <ul class="list-group left-news-menu">
            <li class="list-group-item">
              <router-link to="/news">新闻中心</router-link>
            </li>
            <li
              class="list-group-item"
              v-for="(newsType, index) in websiteNewsType"
              :key="index"
            >
              <router-link
                :to="{ path: '/news?news_type=' + newsType.dictValue }"
                v-if="newsType.active == true"
                class="active"
                >{{ newsType.dictLabel }}</router-link
              >
              <router-link
                :to="{ path: '/news?news_type=' + newsType.dictValue }"
                v-else
                >{{ newsType.dictLabel }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="recommend">
          <h5>为你推荐</h5>
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(news, index) in recommendNews"
              :key="index"
            >
              <router-link
                :to="{ path: 'newsdetails', query: { id: news.newsId } }"
                >{{ news.newsTitle }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- 新闻右 -->
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "News",
  data() {
    return {
      newsID: null,
      //新闻信息
      news: null,
      //下一条新闻
      nextNews: null,
      //上一条新闻
      prevNews: null,
      //为你推荐新闻
      recommendNews: null,
      show: false,
      count: 5,
      timer: null,
      //当前路由地址
      fullPath: null,
      //网站新闻类型
      websiteNewsType: null,
      //选中新闻类型
      chooseNewsType: null,
    };
  },
  mounted() {
    var _this = this;
    _this.newsID = _this.$route.query.id; //获取新闻ID
    //获取新闻信息
    _this.getNews();
  },
  methods: {
    //跳转新闻中心
    clickJump() {
      const timejump = 5;
      this.count = timejump;
      this.show = true;
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= timejump) {
          this.count--;
        } else {
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
          //跳转的页面写在此处
          this.$router.push({ path: "/news" });
        }
      }, 1000);
    },
    //获取新闻信息
    getNews() {
      var _this = this;
      var newsID = _this.newsID;
      if (newsID != null && newsID != "") {
        //ajax获取新闻信息
        _this.$axios
          .get("/system/websitenews/details/" + newsID)
          .then(function (response) {
            if (response.data.code == 200) {
              if (response.data.data.news != undefined) {
                //src地址附加域名
                let content = response.data.data.news.newsContent;
                response.data.data.news.newsContent = content.replace(
                  /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                  (match, p1) => {
                    if(p1.indexOf("data:image")==-1){
                      return (
                        `<img src='` +
                        _this.EditorStaticResDomain +
                        p1 +
                        `' width="100%" />`
                      );
                    }else{
                      return (
                        `<img src='` +p1 +
                        `' style="max-width:795px;" />`
                      );
                    }
                  }
                );
                _this.news = response.data.data.news;
                _this.nextNews = response.data.data.next;
                _this.prevNews = response.data.data.prev;
                _this.recommendNews = response.data.data.recommend;

                //左边菜单信息获取
                var websiteNewsType = response.data.data.news_type;
                var chooseNewsType = response.data.data.news.newsType;
                //循环判断菜单是否是当前新闻ID，若是菜单选中
                for (var i = 0; i < websiteNewsType.length; i++) {
                  if (websiteNewsType[i].dictValue == chooseNewsType) {
                    //获取当前新闻的类型
                    _this.chooseNewsType = websiteNewsType[i];
                    websiteNewsType[i]["active"] = true;
                  } else {
                    websiteNewsType[i]["active"] = false;
                  }
                }
                _this.websiteNewsType = websiteNewsType;
              } else {
                //新闻不存在，跳转新闻中心
                _this.clickJump();
              }
            }
          });
      }
    },
    //顶部菜单选中
    chooseTopMenu() {
      var topMenu = "#/news";
      $("#navbarSupportedContent")
        .find(".navbar-nav")
        .find("a")
        .each(function (index, element) {
          var path = $(element).attr("href");
          if (path == topMenu) {
            $(element).parent("li").addClass("active");
          }
        });
    },
  },
  filters: {
    filtersNewsTitle(val) {
      if (val != null && val != "") {
        return val.substr(0, 12) + "......";
      } else {
        return "";
      }
    },
  },
  watch: {
    //监听路由改变
    $route(to) {
      var _this = this;
      //获取新闻类型
      _this.newsID = to.query.id;
      //获取新闻信息
      _this.getNews();
    },
  },
  updated: function () {
    this.$nextTick(function () {
      var _this = this;
      setTimeout(function () {
        //顶部菜单选中
        _this.chooseTopMenu();
      }, 500);
    });
  },
};
</script>
<style scoped>
.home {
  background-color: #f2f2f2;
  color: #2a333c;
  margin-top: 60px;
}
.news-header {
  height: 120px;
  background: #00c9a1;
  text-align: center;
}
.news-header h2 {
  line-height: 120px;
  color: #37474f;
}
.news-minimenu {
  width: 100%;
  background: #ffffff;
}
.news-minimenu,
.news-minimenu a {
  color: #00c9a1;
}
.news-minimenu svg {
  margin-top: -5px;
}
.news-minimenu a {
  font-size: 16px;
  line-height: 45px;
  margin: 0px 4px;
}
.details-title {
  padding: 15px 20px;
  color: #555555;
  border-bottom: 1px solid #eeeeee;
}
.details-title h1 {
  font-size: 30px;
  line-height: 1.5;
}
.details-title .info {
  font-size: 16px;
}
.details-title .info span,
.details-title .info svg {
  margin-right: 8px;
  color: #2a333c;
}
.met-editor {
  padding: 30px;
  font-size: 16px;
  line-height: 1.8;
}
.met-editor p {
  margin-bottom: 0px;
}
.news-details .pagination {
  padding-top: 20px;
  border-top: 1px solid #f0f2f5;
}
.news-details .pagination li {
  width: 50%;
  border: none;
}
.news-details .pagination li .page-link {
  display: inline;
  border-radius: 5px;
  color: #999999;
}
.news-details .pagination li .page-link:hover {
  color: #00c9a1;
  background: #ffffff;
}
.news-details .pagination li:first-child a {
  float: left;
  margin: 0px 20px !important;
}
.news-details .pagination li:last-child a {
  float: right;
  margin: 0px 20px;
}

.news-cont {
  display: flex;
  padding: 30px 0px;
}
.news-CL,
.news-CR {
  background-color: #ffffff;
  padding: 15px 0px;
}
.news-CR {
  padding: 20px;
  margin: 0px 0px 0px 20px;
}
.input-search .input-search-btn {
  position: absolute;
  top: 25px;
  right: 30px;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.input-search .form-control {
  border-radius: 2rem;
}
.input-search .form-control:focus {
  border: 1px solid#00c9a1;
  box-shadow: none;
}
.recommend {
  border-top: 1px solid #f0f2f5;
  margin: 20px 0px;
  padding: 20px 0px 0px 0px;
}
.left-menu .list-group,
.left-menu .list-group .list-group-item {
  border: none;
  padding: 5px 0px;
  font-size: 16px;
}
.left-menu .list-group .list-group-item .active {
  color: #00c9a1;
}
.recommend h5 {
  color: #555555;
  font-weight: 300;
}
.recommend a,
.left-menu a {
  color: #555555;
}
.recommend a:hover,
.left-menu a:hover {
  text-decoration: none;
  color: #00c9a1;
}
.no-news {
  text-align: center;
  margin-top: 20%;
  font-size: 18px;
}
.no-news a {
  color: #00c9a1;
  margin-left: 10px;
}
</style>
<style>
.met-editor p {
  margin-bottom: 0.2rem !important;
}
</style>
